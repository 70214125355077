import { defineStore } from 'pinia';
import type { Echeancier } from '~/types/models/echeancier';

export const useEcheanciersStore = defineStore('echeanciers', {
  state: () => ({
    echeanciers: [] as Echeancier[],
    page: 1,
    pageCount: 1,
    sortField: 'libelle',
    sortDirection: ':asc',
    query: '',
    currentEcheancier: null as Echeancier | Creatable<Echeancier> | null,
  }),
  getters: {
    getFullSortField: (state) => (state.sortField ? state.sortField + state.sortDirection : ''),
  },
  actions: {
    async fetch() {
      const res = await useApi.echeanciers.find(this.page, this.getFullSortField, this.query);
      if (res && res.data) {
        this.echeanciers = res.data;
        this.page = res.meta?.pagination.page || 1;
        this.pageCount = res.meta?.pagination.pageCount || 1;
      }
    },

    async create(echeancier: Echeancier) {
      const res = await useApi.echeanciers.create(echeancier);
      if (res && res.data) {
        this.echeanciers.push(res.data);
        return res.data;
      }
      return null;
    },

    async update(echeancier: Echeancier) {
      const res = await useApi.echeanciers.update(echeancier);
      if (res && res.data) {
        const index = this.echeanciers.findIndex((item) => item.id === echeancier.id);
        this.echeanciers[index] = res.data;
        return res.data;
      }
      return null;
    },

    async delete(echeancier: Echeancier) {
      const res = await useApi.echeanciers.delete(echeancier);
      if (res && res.data) {
        const index = this.echeanciers.findIndex((item) => item.id === echeancier.id);
        this.echeanciers.splice(index, 1);
        return res.data;
      }
      return null;
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query: string) {
      this.page = 1;
      this.query = query;
    },

    setCurrentEcheancier(echeancier: Echeancier | Creatable<Echeancier> | null) {
      this.currentEcheancier = echeancier;
    },

    async refresh() {
      await this.fetch();
    },

    reset() {
      this.echeanciers = [];
      this.currentEcheancier = null;
    },

    getEcheanceByCode(code: string, echeancier: Echeancier) {
      return echeancier && echeancier.echeances.find((e: any) => e.code === code);
    },
  },
});
